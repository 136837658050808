.fadeinout {
    animation: fadeinout 3s infinite;
}

.animate_wrap {
    position: absolute;
    z-index: 50;
}

.bg_screen {
    background: url('../../assets/scanRound.png');
    background-size: cover;
    min-height: calc(100vh - 200px);
    height: calc(100vh - 500px);
    width: 100vw;
    margin-right: -20px !important;
    margin-left: -20px !important;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;
}

.success_bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bg_screen::before {
    position: absolute;
    height: 324px;
    width: 318px;
    top: 52%;
    left: 50%;
    content: "";
    transform: translate(-50%, -50%);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    background-image: url('../../assets/auraScan1.png');
    /* animation: image 2s infinite alternate; */
}

.bg_chakra::before {
    background-image: url("../../assets/auraScan.png") !important;
    width: 332px;
}

.scan-img-wrap img {
    width: 110px;
    height: 110px;
    border-radius: 25px;
    background: transparent;
}

.scan-img-wrap {
    position: absolute;
    left: 50%;
    top: 52.5%;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: flex;
    justify-content: center;
}
.scan-btn-wrap {
    position: absolute;
    bottom: -3%;
    width: 100%;
    left: 50%;
    transform: translate(-50%);
    z-index: 20;
}

.scan-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader-wrap {
    text-align: center;
}

.animate_wrap img {
    width: 100%;
}

.modal_dialog {
    height: 50vh;
    background-color: #0d3c61;
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type="text"] {
    color: #fff;
}

.pdf-btn-wrap {
    position: absolute;
    bottom: 10%;
    right: 0px;
}

.pdf-btn-wrap button {
    color: #fff;
    font-size: 30px;
}

@media screen and (max-width:575px) {
    .modal_dialog {
        height: 35vh;
    }

    .scan-btn-wrap {
        width: 100%;
    }
}

@keyframes fadeinout {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/************ user recorder css *************/
.userRecoderORCon{
    padding: 10px 20px;
}
 
.userRecoderORCon .MuiFormControl-root.MuiTextField-root,
.userRecoderORCon textarea,
.userRecoderORCon button {
    border-radius: 8px;
    border: 4px solid #07263e;
    position: relative;
    background: #133e5f !important;
    color: #fff;
    max-width: 100%;
    min-width: 0;
    box-sizing: border-box;
}

.userRecoderORCon .MuiFormControl-root.MuiTextField-root::after,
.userRecoderORCon .MuiFormControl-root.MuiTextField-root::before,
.userRecoderORCon textarea::after,
.userRecoderORCon textarea::before,
.userRecoderORCon button:not([aria-label="Choose date"])::after,
.userRecoderORCon button:not([aria-label="Choose date"])::before {
    content: "";
    position: absolute;
    left: 0;
    top: -4px;
    right: 0;
    margin: auto;
    height: 4px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50,ffffff+100&0+0,1+50,0+100 */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.userRecoderORCon .MuiFormControl-root.MuiTextField-root::after,
.userRecoderORCon textarea::after,
.userRecoderORCon button:not([aria-label="Choose date"])::after {
    top: auto;
    bottom: -4px;
}

.userRecoderORCon .MuiFormControl-root.MuiTextField-root input::placeholder,
.userRecoderORCon textarea::placeholder {
    color: #fff;
    text-align: center;
    opacity: 1;
    text-transform: capitalize;
}

.userRecoderORCon textarea,
.userRecoderORCon textarea::placeholder {
    text-align: left;
    padding: 10px;
    background: #17659c !important;
}

.userRecoderORCon button.MuiButtonBase-root.customBtn.customStartBtn {
    color: red;
}

.userRecoderORCon button.MuiButtonBase-root.MuiButton-containedPrimary {
    width: 100%;
    font-size: 20px;
    padding: 10px 20px 10px 20px !important;
}

.userRecoderORCon button svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    fill: #fff;
}

.ks .MuiDialog-paperWidthSm {
    max-width: 710px !important;
    height: 610px;
}

.ks .MuiDialogContent-root:first-child {
    padding-top: 20px;
    text-align: center;
    width: 688px;
    max-width: 100%!important;
}
.ks .MuiDialogContent-root:first-child img{
    max-width: 100% !important;
}
.ks .MuiDialogContent-root:first-child video{
    max-height: 50vh;
    max-width: 100%;
}

.custom-pop-up .MuiDialog-paperWidthSm {
    max-width: 75%;
    width: 100%;
    min-height: 650px;
    height: auto;
}
#custom-date-area label {
    color: #fff !important;
}

.MuiDialogContent-root img.false {
    max-width: 100% ;
    height: auto;
}
div#birth-date-area div:first-child {
    padding-bottom: 6px;
}